import React, { useState } from "react";
import "./Content.css";
import { Link, animateScroll as scroll } from "react-scroll";
import profilephoto from "../../Assets/DSC_0790.jpg";

import {
  FaGithub,
  FaHamburger,
  FaLink,
  FaLinkedin,
  FaStar,
} from "react-icons/fa";
import { ReactTyped } from "react-typed";

const Content = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleclick = () => {
    setIsOpen(false);
  };

  return (
    <div className="content">
      <nav className="sidebar">
        <div className="siderbar-div">
          <div className="sidebar-nav__image">
            <img className="siderbar-nav__profile_photo" src={profilephoto} />
          </div>

          <ul className="sidebar-nav">
            <li className="sidebar-nav__item">
             
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                > <a href="" className="sidebar-nav__link">
                  About</a>
                </Link>
              
            </li>

            <li className="sidebar-nav__item">
              
                <Link
                  activeClass="active"
                  to="experience"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                ><a href="" className="sidebar-nav__link">
                  Experience</a>
                </Link>
              
            </li>

            <li className="sidebar-nav__item">
              
                <Link
                  activeClass="active"
                  to="education"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                ><a href="" className="sidebar-nav__link">
                  Education</a>
                </Link>
              
            </li>

            <li className="sidebar-nav__item">
              
                <Link
                  activeClass="active"
                  to="skills"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                ><a href="" className="sidebar-nav__link">
                  Skills</a>
                </Link>
              
            </li>

            <li className="sidebar-nav__item">
              
                <Link
                  activeClass="active"
                  to="interests"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                ><a href="" className="sidebar-nav__link">
                  Interests</a>
                </Link>
              
            </li>

            <li className="sidebar-nav__item">
              
                <Link
                  activeClass="active"
                  to="certification"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <a href="" className="sidebar-nav__link">Certifications</a>
                  
                </Link>
              
            </li>
          </ul>
        </div>
        <div class="legal">&copy; 2024 by James, All rights reserved.</div>
      </nav>
      <main className="main-content">
        <div className="hamburger">
          <button onClick={toggleSidebar} className="hamburger-btn">
            ☰
          </button>

          {isOpen && (
            <div className={`${isOpen ? "dropdown_active" : "inactive"}`}>
              <ul className="sidebar-nav">
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      About
                    </a>
                  </li>
                </Link>
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="experience"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      Experience
                    </a>
                  </li>
                </Link>
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="education"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      Education
                    </a>
                  </li>
                </Link>
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="skills"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      Skills
                    </a>
                  </li>
                </Link>
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="interests"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      Interests
                    </a>
                  </li>
                </Link>
                <Link
                  onClick={handleclick}
                  activeClass="active"
                  to="certification"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="sidebar-nav__item">
                    <a href="" className="sidebar-nav__link">
                      Certifications
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
          )}
        </div>

        <div className="about">
          <div className="about-header">
            <h1 className="about-header__greet">Hi, I'm</h1>
            <h1 className="about-header__greet">
              James{" "}
              <span className="about-header__greet__secondname">higiro</span>
            </h1>
            <h1 className="about-header__career">
              <ReactTyped
                strings={[
                  "Passionate Software Engineer",
                  "Full-stack Developer",
                  "Frontend Software Developer(React js, Flutter)",
                  "Mobile Application Developer",
                  "Backend Developer",
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </h1>
          </div>

          <div className="about-header__adress">
            <div>
              <span className="about-header__adress__icon">
                <FaStar />
              </span>
              <span className="about-header__adress__phone">+250783714896</span>
            </div>
            <div>
              <span className="about-header__adress__icon__email">
                <FaLink />
              </span>
              <span className="about-header__adress__email">
                jameshigiro@gmail.com
              </span>
            </div>
          </div>

          <p className="about-header__bio">
            Experienced software engineer with 2 year experience. Strong
            background in algorithm development and proficiency in multiple
            programming languages. Proven ability to deliver software solutions
            in a fast-paced environment. Skilled in software development
            principles, eager to continue honing skills and contributing to
            innovative software development projects.
          </p>

          <a href={`${process.env.PUBLIC_URL}/CV_HIGIRO_JAMES.pdf`} download="CV James Higiro.pdf" className="about-header__resume">
            download my resume
          </a>

          <div className="about-header__socialmedias">
            <span className="about-header__socialmedias_platform about-header__socialmedias__linkedin">
              <a href="https://www.linkedin.com/in/james-higiro" target="_blank" className="socialplatform">
                <FaLinkedin />
              </a>
              
            </span>
            <span className="about-header__socialmedias_platform about-header__socialmedias__github">
              <a href="https://github.com/JamesHigiro?tab=repositories" target="_blank" className="socialplatform">
                <FaGithub />
              </a>
              
            </span>
          </div>
        </div>

        <div className="experience">
          <h1 className="experience-field">experience</h1>
          <div className="experience-details">
            <div className="experience-container">
              <h3 className="experience-title">Software Engineer</h3>
              <h4 className="experience-firm">Teamflow GmbH</h4>
            </div>

            <div className="experience-date">
              <h5 className="experience-date__date">
                Since April 2024
              </h5>
            </div>
          </div>
          <div className="experience-details">
            <div className="experience-container">
              <h3 className="experience-title">mobile application developer</h3>
              <h4 className="experience-firm">TUZAride</h4>
            </div>

            <div className="experience-date">
              <h5 className="experience-date__date">
                May 2023 - September 2023
              </h5>
            </div>
          </div>

          <div className="experience-details">
            <div className="experience-container">
              <h3 className="experience-title">Frontend developer</h3>
              <h4 className="experience-firm">
                CMU-AFRICA, Research assistant
              </h4>
            </div>

            <div className="experience-date">
              <h5 className="experience-date__date">
                October 2023 - February 2024
              </h5>
            </div>
          </div>

          <div className="experience-details">
            <div className="experience-container">
              <h3 className="experience-title">Backend developer</h3>
              <h4 className="experience-firm">
                Ishyiga algorithm Inc, Internship
              </h4>
            </div>

            <div className="experience-date">
              <h5 className="experience-date__date">
                January 2022 - March 2022
              </h5>
            </div>
          </div>

          <div className="experience-details">
            <div className="experience-container">
              <h3 className="experience-title">Backend developer</h3>
              <h4 className="experience-firm">
                Centre for fourth industrial revolution
              </h4>
            </div>

            <div className="experience-date">
              <h5 className="experience-date__date">
                September 2023 - December 2023
              </h5>
            </div>
          </div>
        </div>
        <div className="education">
          <h1 className="education-field">Education</h1>
          <div className="education-details">
            <div className="education-container">
              <h3 className="education-title">
                Adventist University of Central africa
              </h3>
              <h4 className="education-firm">
                Bachelor Of Science information technology
              </h4>
              <h5 className="education-studyfield">Software engineering</h5>
              <h5 className="education-gpa">gpa : 3.0</h5>
            </div>

            <div className="education-date">
              <h5 className="education-date__date">
                September 2018 - November 2022
              </h5>
            </div>
          </div>

          <div className="education-details">
            <div className="education-container">
              <h3 className="education-title">
                carnegie mellon University africa
              </h3>
              <h4 className="education-firm">
                Masters Of Science in information technology
              </h4>
              <h5 className="education-studyfield">
                Software engineering & data science
              </h5>
              <h5 className="education-gpa">gpa : 3.6</h5>
            </div>

            <div className="education-date">
              <h5 className="education-date__date">
                September 2022 - May 2024
              </h5>
            </div>
          </div>
        </div>

        <div className="skills">
          <h1 className="skills-title">SKILLS</h1>
          <div className="skills-body">
            <h3 className="skills-frontend__title">FRONTEND TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">React</span>
              </div>
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">HTML5</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">CCS3</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jquery/jquery-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">jQuery</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">JavaScript</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/flutter/flutter-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">flutter</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">BACKEND TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Node</span>
              </div>
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/spring/spring-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Spring</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dart/dart-original-wordmark.svg"
                  className="skills-icons"
                />
                <span className="tooltip">dart</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/express/express-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Express</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Java</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg"
                  className="skills-icons"
                />
                <span className="tooltip">JavaScript</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Python</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">DATABASE TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-plain-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">MySql</span>
              </div>
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">mongodb</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">postgresql</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cassandra/cassandra-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Cassandra</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">COLLABORATION TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/trello/trello-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Trello</span>
              </div>
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original-wordmark.svg"
                  className="skills-icons"
                />

                <span className="tooltip">git</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">github</span>
              </div>

              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/slack/slack-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Slack</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">INTEGRATION TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/travis/travis-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Travis ci</span>
              </div>
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jenkins/jenkins-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Jenkins</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">DESIGNING TOOLS</h3>
            <div className="skills-frontend-tools">
              <div className="icon-container">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/figma/figma-original.svg"
                  className="skills-icons"
                />

                <span className="tooltip">Figma</span>
              </div>
            </div>
          </div>

          <div className="skills-body">
            <h3 className="skills-frontend__title">WORKFLOW</h3>
            <div className="skills-frontend-tools">
              <ul className="skills-frontend__workflow">
                <li className="skills-frontend__workflow_li">
                  Designing database schemas, developing server-side logic/API
                </li>
                <li className="skills-frontend__workflow_li">
                  Mobile first, responsive design, cross-browser
                </li>
                <li className="skills-frontend__workflow_li">
                  Testing and quality assurance{" "}
                </li>
                <li className="skills-frontend__workflow_li">
                  Continuous integration and deployment
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="interests">
          <h1 className="skills-title">interests</h1>
          <p className="interests-details">
            Beyond my passion for software development, I find fulfillment in
            practicing and teaching karate, embracing the discipline and
            artistry it offers. While my appreciation for music is moderate, I
            derive immense pleasure from exploring documentaries that delve into
            the mysteries and scientific wonders of our world, fostering a
            constant curiosity and appreciation for its complexities.
          </p>
        </div>

        <div className="certification">
          <h1 className="skills-title">certification</h1>
          <ul className="certification-details">
            <li>Flutter & Dart Mobile application developer</li>
            <li>Node Js Backend developer</li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Content;
